<template>
  <div
    class="App"
    :class="{
      isMobile: viewport.isMobile,
      isDesktop: viewport.isDesktop,
    }">

    <!-- <AjaxLoader v-if="!isPortalLoaded"/> -->

    <div
      v-if="!isPortalLoaded">
      <!-- @TODO some loader here -->
    </div>

    <router-view
      v-if="isPortalLoaded " 
    />

  </div>
</template>


<script>
import useViewport from '@/use/viewport';
import usePortal from '@/use/portal';
import { useRoute } from 'vue-router';
// import AjaxLoader from '@/components/AjaxLoader.vue'

export default {
  setup() {
    const {
      viewport,
    } = useViewport();

    const {
      portal,
      isPortalLoaded,
    } = usePortal();
    const router = useRoute();
    return {
      viewport,
      portal,
      isPortalLoaded,
      router
    };
  },
  components: {
    // AjaxLoader
  },
  provide() {
    return {
      viewport: this.viewport,
      portal: this.portal,
    };
  },
};
</script>



<style
  src="@/styles/index.scss"
  lang="scss">

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap');
</style>
