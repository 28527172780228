import {
  ref,
  reactive,
  readonly,
} from 'vue';
import Endpoints from '@/use/endpoints';
import Http from '@/services/http';


// Portal
const portal = reactive({});
const isPortalLoaded = ref(false);
const isTermsAccepted = ref(false);
const isSubdomain = ref({
  isLoginRequired : false,
  companyName : ''
})

// For live testing
const hostName = !(["app.portalcx.com", "appdev.portalcx.com", "localhost", "192.168.1.170"].includes(window.location.hostname))
// console.log(window.location.href.split('/').includes('portal'))

// Load Portal
async function loadPortal(portalId = null,token = null,routeName) {
  const userdetails = JSON.parse(localStorage.getItem('user'));
  if (
    isPortalLoaded.value
    && portal.portalId
    && portal.portalId === portalId
    && !userdetails
    && routeName != "login"
  ) return portal;

  try {
    if (!portalId && hostName){
      const { data } = await Http.get(Endpoints.GetBrandingFromSubdomain());
      isSubdomain.value.isLoginRequired = false
      isSubdomain.value.companyName = data.companyName
      setPortal({ portalId, ...data, isdomain: data.companyName ? true :false });
      
      return portal;
    }

    if(token){
      const { data } = await Http.get(Endpoints.brandcolor(portalId));
      setPortal({ portalId, ...data });
      
      return portal;  
    }else{       
      let user = JSON.parse(localStorage.getItem('user')) ?? '';
      let token = {"Authorization": 'Bearer ' + user.token, "Access-Control-Allow-Origin":"http://localhost:8080"} ?? '';
      
      if(portalId){
        const { data } = await Http.get(Endpoints.portal(portalId),{
        headers: token});

        isSubdomain.value.isLoginRequired = data.isCustomerSignInRequired
        isSubdomain.value.companyName = data.companyName
        setPortal({ portalId, ...data });
    }
      if(portalId && !(routeName =='portal' ||routeName =='tos') && hostName){       
        const { data } = await Http.get(Endpoints.brandcolor(portalId));
        setPortal({ portalId, ...data});
        
      }
      
      return portal; 
      
      
    }

  } catch {
    console.error(`ATTEMPT TO ACCESS INVALID PORTAL - ${portalId}`);

    clearPortal();

    throw new Error();
  } finally {
    isPortalLoaded.value = true;
  }
}

// Set Portal
function setPortal(data) {
   
  const stages = data?.stages || [];
  const themeColor = data?.themeColor || data?.projectAppBrandColor || '#44EA9F';
  const textColor = data?.headerColor || data?.projectAppHeaderColor || data?.textColor || '#FFFFFF';   
  const inProgressCount =  stages.indexOf(stages.find((i) => i.dateCompleted == ''))  
  const stagesCompletedCount = stages.filter((s) => !!s.dateCompleted).length;

  var projectCompleted = 0;
  if (stagesCompletedCount == stages.length && stages.length != 0) {
    projectCompleted = 1;
  }

  const stageMeta = {
    stagesTotalCount: stages.length,
    stagesCompletedCount,
    stageLastCompleted: stages[stagesCompletedCount - 1] || null,
    stageInProgress: stages[inProgressCount] || null,
    projectCompleted: projectCompleted
  };

  setThemeColor(themeColor);
  setForeGroundColor(textColor);

  const hasTermsAccepted = window.localStorage.getItem(data.portalId);

  if (hasTermsAccepted) {
    isTermsAccepted.value = true;
  }

  return Object.assign(portal, {
    ...stageMeta,
    ...data,
  });
}


// Clear Portal
function clearPortal() {
  Object.keys(portal).forEach((key) => delete portal[key]);
}


// Theme Color
function setThemeColor(themeColor) {
  document.querySelector(':root')
    .style.setProperty('--theme-color', themeColor || '#44EA9F');
}

function setForeGroundColor(forgroundColor) {
  document.querySelector(":root").style.setProperty("--foreground-color", forgroundColor || "#FFFFFF");
}


// Accept Terms
function acceptTerms(portalId) {
  window.localStorage.setItem(portalId, true);
  isTermsAccepted.value = true;
}


// Exports
export default function usePortal() {
  return {
    portal,
    loadPortal,
    isPortalLoaded: readonly(isPortalLoaded),
    isTermsAccepted: readonly(isTermsAccepted),
    acceptTerms,
    isSubdomain : readonly(isSubdomain)
  };
};
