import { reactive } from 'vue';
import { throttle } from 'lodash';

// Constants
const DESKTOP_VIEW_PIXEL_WIDTH = 900;


// Viewport
const VIEWPORT = reactive({ ...getViewportProps() });


// Methods
function getViewportProps() {
  const { innerWidth, innerHeight } = window;
  const isDesktop = innerWidth >= DESKTOP_VIEW_PIXEL_WIDTH;

  return {
    width: innerWidth,
    height: innerHeight,
    isMobile: !isDesktop,
    isDesktop,
  };
}


// Handlers
const handleWindowResize = throttle(() => {
  const newViewportProps = getViewportProps();

  Object.keys(VIEWPORT).forEach((key) => {
    VIEWPORT[key] = newViewportProps[key];
  });
}, 20);


// Event Listeners
window.addEventListener('resize', handleWindowResize);


// Exports
export default function useViewport() {
  return {
    viewport: VIEWPORT,
  };
}
