import Http from './http-request';
class AuthenticationService {
    Register(data) {
        return Http.post('/PortalAuthManagement/Register',data)
    }

    RegisterUsingGoogle(data) {
        return Http.post('/PortalAuthManagement/RegisterUsingGoogle',data)
    }

    Login(data) {
        return Http.post('/PortalAuthManagement/Login',data)
    }

    GLogin(data) {
        return Http.post('/PortalAuthManagement/GLogin',data)
    }

    ForgotPassword(data){
        return Http.post('/PortalAuthManagement/ForgotPassword', data)
    }

    SetupPassword(data){
        return Http.post('/PortalAuthManagement/SetupPassword', data)
    }

    GetCustomerProjects(data){
        return Http.get(`/Portal/GetCustomerProjects?projectGuid=${data.portalId}&search=${data.value}`)
    }

    ReadNotification(data){
        return Http.postwithToken(`/Portal/ReadNotification?portalId=${data}`)
    }

    UpdateProfile(data){
        return Http.postwithToken('/PortalAuthManagement/UpdateProfile', data)
    }
 Encryption(data){
        return Http.postwithToken(`/Utility/Encode?PlainText=${data}`)
    }

    Decryption(data){
        return Http.post('/Utility/Decode',data)
    }
}

export default new AuthenticationService();
