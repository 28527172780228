export default [
  {
    path: '/portal/:portalId?',
    name: 'portal',
    component: () => import('@/views/PortalView.vue'),
  },
  {
    path: '/tos/:portalId?',
    name: 'tos',
    component: () => import('@/views/TosView.vue'),
  },
  {
    path: '/',
    name: 'login1',
    component: () => import('@/views/signIn/login.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import('@/views/404View.vue'),
  },
  {
    path: '/signup/:portalId?',
    name: 'signUp',
    component: () => import('@/views/signIn/signUp.vue'),
  },
  {
    path: '/set-password/:portalId?/:token',
    name: 'set-password',
    component: () => import('@/views/signIn/setPassword.vue'),
  },
  {
    path: '/forgot-password/:portalId?',
    name: 'forgot-password',
    component: () => import('@/views/signIn/ForgotPassword.vue'),
  },
  {
    path: '/login/:portalId?',
    name: 'login',
    component: () => import('@/views/signIn/login.vue'),
  },
  // {
  //   path: '/project-list/:portalId?',
  //   name: 'project-list',
  //   component: () => import('@/views/ProjectListView.vue'),
  // }
{
    path: '/oauth',
    name: 'oauth',
    component: () => import('@/views/OauthView.vue'),
  },
  {
    path: '/googleLogin',
    name: 'glogin',
    component: () => import('@/views/googleLogin.vue'),
  }
];
