const afterEach = (to) => {
    if (typeof window.gtag === "function") {
      window.gtag('event', 'page_view', {
        'page_path': to.path,
        'page_title': to.name,
        // ... any other data you want to send with the pageview
      });
    }
  };
  
  export default afterEach;