import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import http from './plugins/http';
import {vfmPlugin}  from 'vue-final-modal';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css';
import "vue3-circle-progress-bar/dist/circle-progress-bar.css";
import VueGtm from 'vue-gtm';
import vue3GoogleLogin from 'vue3-google-login'

// PORTALCX ID
const CLIENT_ID = '905121660586-04n3esi5k3e7l5opikvnoum21iuj83aq.apps.googleusercontent.com'

const app = createApp(App);

// Remove with next minor release:
app.config.unwrapInjectedRef = true;

// Google Tag Manager
app.use(VueGtm, {
  id: 'GTM-TQMLTQV',
  vueRouter: router
});

app
  .use(router)
  .use(http)
  .use(vfmPlugin({
    key: '$vfm',
    componentName: 'VueFinalModal',
    dynamicContainerName: 'ModalsContainer'
  }))
  .use(Toast, {  
    filterBeforeCreate: (toast, toasts) => {
    if (toasts.filter(
      t => t.type === toast.type
    ).length !== 0) {
      // Returning false discards the toast
      return false;
    }
    // You can modify the toast if you want
    return toast;
  }
})
  .use(FloatingVue)
  .use(vue3GoogleLogin,{clientId: CLIENT_ID})
  .mount('#app');
