import usePortal from '@/use/portal';
import AuthenticationService from '../services/AuthenticationService.service';
import authHeader from '@/services/auth-header';

const {
  loadPortal,
  isTermsAccepted,
  isSubdomain
} = usePortal();
const subdomain = !["app.portalcx.com", "appdev.portalcx.com", "localhost", "10.0.1.192"].includes(window.location.hostname)

export default async function beforeEach(to) {
  try {
    // if(to.name == 'set-password'){
    //   return true;
    // }else{
      await loadPortal(to.params?.portalId,to.params?.token,to.name);
    // }
  } catch {
      if (to.name !== '404') {
          // GTM tracking for portal loading failure
          if (typeof window.dataLayer === 'object') {
              window.dataLayer.push({
                  'event': 'portal_load_failure',
                  'portalId': to.params?.portalId
              });
          }
          return {
              name: '404',
              replace: true,
          };
      }
  }

  const userdetails = JSON.parse(localStorage.getItem('user'));
  if(to.name === '404'){
    if(userdetails){
      localStorage.clear('user')
      localStorage.clear('projectGUIDs')      
      return true
    }    
  }
  if (to.name === '404') return true

  if( to.name == 'portal' && to.params?.portalId && !isSubdomain.value.isLoginRequired){
    AuthenticationService.ReadNotification(to.params?.portalId).then((response) => { }).catch(()=>{});
  }
  
  if (!isTermsAccepted.value && to.name !== 'tos' && to.name !== 'set-password' && !userdetails  && !isSubdomain.value.isLoginRequired && to.params?.portalId) {return {
    name: 'tos',
    params: { portalId: to.params?.portalId },
    replace: true,
  };}

  if (isTermsAccepted.value && to.name === 'tos') return {
    name: 'portal',
    params: { portalId: to.params?.portalId },
    replace: true,
  };

  if (isSubdomain.value.isLoginRequired && to.name === 'portal'){
    return {
    name: 'login',
    params: { portalId: (subdomain && isSubdomain.value.companyName != null) ? '' : to.params?.portalId },
    replace: true,
  };
}

if (!isSubdomain.value.isLoginRequired && subdomain && isSubdomain.value.companyName == null && to.name != 'glogin'){
  return {
  name: '404',
  replace: true,
};
}

if (!subdomain && !(to.params?.portalId || to.query?.PortalId) && !to.query?.state){
  return {
  name: '404',
  replace: true,
};
}
  if(localStorage.getItem('user')){
   if(to.name == 'login' || to.name == 'signup' || to.name == 'set-password' || to.name == 'forgot-password' ){
     return {
      name: 'portal',
      params: { portalId: JSON.parse(localStorage.getItem('user')).projectGuid },
      replace: true,
    }
   }
  }
  // if (
  //   to.params.portalId &&
  //   to.name !== "portal" &&
  //   to.name !== "tos" &&
  //   ![
  //     "app.portalcx.com",
  //     "appdev.portalcx.com",
  //     "localhost",
  //     "192.168.1.170",
  //   ].includes(window.location.hostname)
  // ) {
  //   // console.log("before each last condition",![
  //   //   "app.portalcx.com",
  //   //   "appdev.portalcx.com",
  //   //   "localhost",
  //   //   "192.168.1.170",
  //   // ].includes(window.location.hostname))
  //   // console.log(window.location.hostname)
  //   // console.log(to.params.portalId)
  //   return {
  //     replace: true,
  //   };
  // }
  
  return true;
};
