import axios from 'axios';

// Config
//  axios.defaults.baseURL = 'http://192.168.1.170:4000/api';
// axios.defaults.baseURL = 'http://203.109.113.154:8092/api'
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;


// Request Interceptor
axios.interceptors.request.use((request) => request);

// Response Interceptor
axios.interceptors.response.use((response) => response);

export default axios;
