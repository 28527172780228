import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes';
import scrollBehavior from './scroll-behavior';
import beforeEach from './before-each';
import afterEach from './after-each';

// Provision Router
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior,
});

// Navigation Guards
router.beforeEach(beforeEach);
router.afterEach(afterEach);

// Exports
export default router;
